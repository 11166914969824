import navigation from './components/navigation';
import video from './components/video';

window.addEventListener('load', () => {
    navigation();
    const wpVideo = Array.from(document.querySelectorAll('.featured-video'));
    if (wpVideo.length > 0) {
        video(wpVideo);
    }
});
