export default video => {
    video.forEach(v => {
        const iframe = v.querySelector('.video-embed');
        const placeholder = v.querySelector('.video-placeholder');
        const buttons = v.querySelectorAll('.place-click');

        buttons.forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                iframe.classList.add('show');
                placeholder.classList.add('hide');
            });
        });
    });
};
